'use client';

import { captureException } from '@sentry/nextjs';
import { useEffect } from 'react';

const GlobalError = ({ error }: { error: Error & { digest?: string } }) => {
  useEffect(() => {
    captureException(error);
  }, [error]);
};

export default GlobalError;
